import React from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';

function LoginPage({ handleScrollToConsultation }) {
  const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate('/');
    setTimeout(() => {
      handleScrollToConsultation();
    }, 500);
  };

  return (
    <div className="login-page">
      <div className="form-container">
        <h2>Login</h2>
        <p>PathEye Login and Training Access</p>
        <p>
          Whether you're an administrator accessing PathEye or a user seeking to log in for your training sessions, you've come to the right place.
        </p>
        <form>
          <div className="form-group">
            <label>Email</label>
            <input type="email" required />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" required />
          </div>
          <button type="submit" className="transparent-buttonlogin">
            Login <span>&rarr;</span>
          </button>
        </form>
        <p className="demo-link">
          Not a customer? Find out how PathEye can secure your business.{' '}
          <span onClick={handleDemoClick} className="demo-link-text">Schedule a consultation &gt;</span>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
