import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ScheduleConsultationSection.css';
import emailjs from 'emailjs-com';

function ScheduleConsultationSection() {
  const [startDate, setStartDate] = useState(new Date());
  const [timeZone, setTimeZone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    timeZone: '',
    date: new Date(),
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email with EmailJS
    emailjs
      .sendForm('service_txvykq3', 'template_xin3oyw', e.target, 'VelC2Zm_hcQa4Ewy8')
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true); // Show confirmation message
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="schedule-consultation-section">
      <h2 className="section-title-ima">Schedule a Confidential Consultation</h2>
      {!submitted ? (
        <form className="consultation-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label>First Name *</label>
              <input
                type="text"
                name="firstName"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Last Name *</label>
              <input
                type="text"
                name="lastName"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Work Email *</label>
              <input
                type="email"
                name="email"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                className="form-control"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Select a Date and Time *</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={30}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                minTime={new Date().setHours(8, 0)}
                maxTime={new Date().setHours(20, 0)}
                className="form-control"
                name="date"
              />
            </div>
            <div className="form-group">
              <label>Time Zone *</label>
              <input
                type="text"
                name="timeZone"
                value={timeZone}
                onChange={(e) => setTimeZone(e.target.value)}
                required
                className="form-control"
              />
            </div>
          </div>
          <p className="privacy-text">
            By clicking submit, you agree to our <a href="/privacy-policy" className="no-underline">privacy policy</a> and consent to be contacted by PathEye. PathEye is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You may unsubscribe from these communications at any time.
          </p>
          <button type="submit" className="transparent-button">
            Submit <span>&rarr;</span>
          </button>
        </form>
      ) : (
        <p className="confirmation-message">Thank you! Your consultation has been scheduled.</p>
      )}
    </section>
  );
}

export default ScheduleConsultationSection;
