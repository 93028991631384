import React, { useState, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com'; // Import emailjs
import './ConsultationSection.css';

const ConsultationSection = forwardRef((props, ref) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    notes: '',
  });

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Format phone number as (555) 123-1234 with extension support
  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    let formattedPhone = '';
    if (input.length > 0) {
      formattedPhone = `(${input.slice(0, 3)})`;
    }
    if (input.length >= 4) {
      formattedPhone += ` ${input.slice(3, 6)}`;
    }
    if (input.length >= 7) {
      formattedPhone += `-${input.slice(6, 10)}`;
    }

    if (input.length > 10) {
      formattedPhone += ` x${input.slice(10)}`; // Allow extensions after 10 digits
    }

    setPhone(formattedPhone);
    setFormData({
      ...formData,
      phone: formattedPhone,
    });
  };

  // Disable weekends and past dates in the date picker
  const isWeekday = (date) => {
    const day = new Date(date).getDay();
    return day !== 0 && day !== 6; // Exclude weekends (0 = Sunday, 6 = Saturday)
  };

  // Generate time slots for 8 AM - 8 PM in 30-minute intervals
  const generateTimeSlots = () => {
    const times = [];
    const start = 8 * 60; // 8 AM in minutes
    const end = 20 * 60; // 8 PM in minutes
    for (let i = start; i <= end; i += 30) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const timeString = `${hours > 12 ? hours - 12 : hours}:${minutes === 0 ? '00' : minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
      times.push(timeString);
    }
    return times;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email with EmailJS
    emailjs
    .sendForm('service_txvykq3', 'template_xin3oyw', e.target, 'VelC2Zm_hcQa4Ewy8')
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true); // Show confirmation message
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="consultation-section" ref={ref}>
      <h2 className="section-title-consultation">Schedule a Confidential Consultation</h2>
      {!submitted ? (
        <form className="consultation-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label>First Name *</label>
              <input
                type="text"
                name="firstName"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Last Name *</label>
              <input
                type="text"
                name="lastName"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Work Email *</label>
              <input
                type="email"
                name="email"
                required
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Phone *</label>
              <input
                type="tel"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Select a Date *</label>
              <input
                type="date"
                name="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                required
                className="form-control"
                min={new Date().toISOString().split("T")[0]} // Set min date to today
                onInput={(e) => {
                  if (!isWeekday(e.target.value)) {
                    e.target.setCustomValidity('Please select a weekday (Mon-Fri).');
                  } else {
                    e.target.setCustomValidity('');
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label>Select a Time (EST)*</label>
              <select
                name="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                required
                className="form-control"
              >
                <option value="">Select Time</option>
                {generateTimeSlots().map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Notes</label>
              <textarea
                name="notes"
                className="form-control"
                onChange={handleChange}
                placeholder="Any additional information..."
              ></textarea>
            </div>
          </div>
          <p className="privacy-text">
            By clicking submit, you agree to our <Link to="/privacy-policy" className="no-underline">privacy policy</Link> and consent to be contacted by PathEye. PathEye is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. You may unsubscribe from these communications at any time.
          </p>
          <button type="submit" className="transparent-button">
            Submit <span>&rarr;</span>
          </button>
        </form>
      ) : (
        <p className="confirmation-message">Thank you! Your consultation has been scheduled. You will receive a calendar invite within the coming business day.</p>
      )}
    </section>
  );
});

export default ConsultationSection;
